<template>
    <properties-component v-bind="$props" @close="$emit('close')">

        <b-form-group label-cols="4" :label="'Display|Afficher'|tr" v-if="component.useBinding">
            <select-cms-item-field :item-type="block.itemType" v-model="component.textField" @format-change="component.format = $event" field-type="any-text"/>
        </b-form-group>

        <b-form-group v-if="textFieldDef && textFieldDef.formats" label-cols="4" label="Format">
            <b-select v-model="component.format">
                <option :value="undefined" v-tr>None|Sans</option>
                <option v-for="format in textFieldDef.formats" :key="`format-${format.value}`" :value="format.value">{{format.label|tr}}</option>
            </b-select>
        </b-form-group>

        <b-form-group label-cols="4">
            <template #label>
                <span v-tr>Style</span>
                <btn-responsive-prop v-model="component" field="preset"/>
            </template>
            <b-input-group>
                <b-select v-model="component.mediaProxy.preset">
                    <option value="h1" class="h1" v-tr>Headline 1|Titre 1</option>
                    <option value="h2" class="h2" v-tr>Headline 2|Titre 2</option>
                    <option value="h3" class="h3" v-tr>Headline 3|Titre 3</option>
                    <option value="h4" class="h4" v-tr>Title 1|Sous-titre 1</option>
                    <option value="h5" class="h5" v-tr>Title 2|Sous-titre 2</option>
                    <option value="h6" class="h6" v-tr>Title 3|Sous-titre 3</option>
                    <option value="p1" class="p1" v-tr>Paragraph 1|Paragraphe 1</option>
                    <option value="p2" class="p2" v-tr>Paragraph 2|Paragraphe 2</option>
                    <option value="p3" class="p3" v-tr>Paragraph 3|Paragraphe 3</option>
                    <option value="p4" class="p4" v-tr>Paragraph 4|Paragraphe 4</option>
                    <option value="p5" class="p5" v-tr>Paragraph 5|Paragraphe 5</option>
                    <option value="p6" class="p6" v-tr>Paragraph 6|Paragraphe 6</option>
                    <option value="default" v-tr>Default|Défaut</option>
                </b-select>
                <btn-toggle v-model="component.uppercase"><i class="fas fa-text-size"/></btn-toggle>
            </b-input-group>
        </b-form-group>

        <b-form-group label-cols="4" label="Format" v-if="component.useBinding || component.widget">
            <b-btn-group>
                <input-color v-model="component.color" allow-default/>
                <btn-toggle v-model="component.bold"><i class="fas fa-bold"/></btn-toggle>
                <btn-toggle v-model="component.italic"><i class="fas fa-italic"/></btn-toggle>
                <btn-toggle v-model="component.underline"><i class="fas fa-underline"/></btn-toggle>
<!--                <btn-toggle v-model="component.uppercase"><i class="fas fa-text-size"/></btn-toggle>-->
            </b-btn-group>
        </b-form-group>

        <sidebar-group v-if="component.advanced" title="Advanced Settings|Options avancées" sub-group>
            <b-form-group :label="'Widget'|tr">
                <b-select v-model="component.widget">
                    <option :value="false" v-tr>No widget|Pas de widget</option>
                    <option value="date" v-tr>Date</option>
                    <option value="time" v-tr>Time|Heure</option>
                </b-select>
            </b-form-group>

            <b-form-group>
                <template #label>
                    <span v-tr>Prefix|Prefixe</span>
                    <btn-responsive-prop v-model="component" field="prefix" not-inline/>
                </template>
                <e-translation-input type="text" v-model="component.mediaProxy.prefix" auto-translate no-margin/>
            </b-form-group>
            <b-form-group>
                <template #label>
                    <span v-tr>Suffix|Suffixe</span>
                    <btn-responsive-prop v-model="component" field="suffix" not-inline/>
                </template>
                <e-translation-input type="text" v-model="component.mediaProxy.suffix" auto-translate no-margin/>
            </b-form-group>

            <b-form-group :label="'Icons|Icônes'|tr">
                <b-row>
                    <b-col>
                        <btn-responsive-prop v-model="component" field="leftIcon" style="left: -20px; top: 12px"/>
                        <input-glyph v-model="component.mediaProxy.leftIcon" placeholder=""/>
                    </b-col>
                    <b-col>
                        <btn-responsive-prop v-model="component" field="rightIcon" style="left: -10px; top: 12px"/>
                        <input-glyph v-model="component.mediaProxy.rightIcon" placeholder=""/>
                    </b-col>
                </b-row>
            </b-form-group>
        </sidebar-group>
        <slot></slot>
    </properties-component>
</template>

<script>
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import BtnToggle from "@/components/editor/properties/inputs/btn-toggle.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputGlyph from "@/components/editor/properties/inputs/input-glyph.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";
import ETranslationInput from "../../../../../vue-components/components/e-translation-input.vue";

// properties-component-text #hot-reload-debug
export default {
    name: `properties-component-text`,
    components: {ETranslationInput, BtnResponsiveProp, InputGlyph, EInput, SidebarGroup, InputColor, SelectCmsItemField, BtnToggle, PropertiesComponent},
    extends: PropertiesComponent,
    computed: {
        textFieldDef() {
            if (!this.block.itemTypeDef || !this.component.textField) {
                return null;
            }
            return this.cms.getFieldDef(this.block.itemTypeDef, this.component.textField);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
